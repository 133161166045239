import * as React from "react";
import { graphql } from "gatsby";
import "@fontsource/red-hat-display";
import Layout from "../components/layout/layout";
import Seo from "../components/layout/seo";
import logo from "/src/images/iso_color.png";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import { Box, Container, Grid, Typography } from "@mui/material";

let cs_theme = createTheme({
  palette: {
    primary: {
      main: "#FFA100",
    },
    secondary: {
      main: "#00C5B7",
    },
    white: "#FFFFFF",
    black: "#202026",
    purple: "#8F4FFF",
  },
  typography: {
    fontFamily: ["Red Hat Display"].join(","),
  },
});
cs_theme = responsiveFontSizes(cs_theme);

const Contact = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;
  let header;
  header = <img style={{ width: "50px" }} src={logo} alt="Logo" />;
  return (
    <ThemeProvider theme={cs_theme}>
      <Layout location={location} title={siteTitle}>
        <Seo title="Contact us | Custom Surgical" />
        <Box className="contact-us">
          <Container maxWidth="lg">
            <Grid
              container
              justifyContent={"center"}
              paddingTop={{ xs: 50, sm: 40 }}
            >
              <Grid item xs={10}>
                <Typography
                  variant="h2"
                  color="white"
                  fontWeight={"bold"}
                  style={{ textShadow: "#000 1px 0 10px" }}
                >
                  Contact us
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Container xs={12} maxWidth={"lg"}>
          <Grid
            container
            xs={12}
            style={{ justifyContent: "center" }}
            marginTop={8}
          >
            <Grid item sm={5} xs={10}>
              <h1 style={{ marginTop: "0" }}>Contacts</h1>
            </Grid>
            <Grid item sm={5} xs={10}>
              <p style={p}>
                For more information about MicroREC or our other products,
                please contact{" "}
                <a
                  href="mailto:commercial@customsurgical.co"
                  className="links_post"
                >
                  commercial@customsurgical.co
                </a>
              </p>
              <p style={p}>
                For new projects or general information, please contact{" "}
                <a
                  href="mailto:info+contact@customsurgical.co"
                  className="links_post"
                >
                  info+contact@customsurgical.co
                </a>
              </p>
              <p style={p}>
                If you are an influencer or have a PR request, please contact{" "}
                <a
                  href="mailto:giuliana@customsurgical.co"
                  className="links_post"
                >
                  giuliana@customsurgical.co
                </a>
              </p>
            </Grid>
          </Grid>
          <Grid
            container
            xs={12}
            style={{ justifyContent: "center" }}
            marginTop={8}
          >
            <Grid item sm={5} xs={10}>
              <h1 style={{ marginTop: "0" }}>Visit Us</h1>
            </Grid>
            <Grid item sm={5} xs={10}>
              <p style={p}>Münchener Technologiezentrum</p>

              <p style={p}>Agnes-Pockels-Bogen 1, 80992 Munich, Germany</p>

              <p style={p}>+491782064226</p>
            </Grid>
          </Grid>
        </Container>
      </Layout>
    </ThemeProvider>
  );
};

const p = {
  fontSize: "18px",
};

export default Contact;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
